import {IPost} from '@api/post/getPostDetail'
import {ApiRes} from '@type/api'
import {apiGet} from '@api/api'

export type AnnounceType = 'all' | 'announcement' | 'media' | 'event' | 'others' | 'news' | string
export type AnnounceTagsType = 'new' | 'important' | string

export interface IAnnouncement extends IPost {
    categories: AnnounceType[]
    tags: AnnounceTagsType[]
    thumbnail?: string
    important_at?: number
}

export const apiGetAnnouncements = async (
    category?: AnnounceType,
    current_page: number = 1,
    page_size?: number,
    before?: number,
    language?: string,
): Promise<ApiRes<IAnnouncement[]>> => {
    return apiGet(
        `official/posts?category=${
            category ?? ''
        }&current_page=${current_page}&page_size=${page_size}&before=${before}`,
        {cookies: {language: language}},
    )
}
